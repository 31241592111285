<template>
  <v-dialog :value="value" persistent max-width="350px" @input="$emit('input', $event)">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <v-card :disabled="loading" :loading="loading">
        <v-card-title class="grey lighten-5">
          {{ $t("hr.debit_tickets.debit_transfer") }}
          <v-spacer />
          <v-btn depressed icon @click="onClickCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="debitTicket">
          <v-flex xs12>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("hr.debit_tickets.asset") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ debitTicket.asset.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("hr.debit_tickets.quantity") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ debitTicket.quantity || "-" }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("hr.debit_tickets.borrow_date") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ debitTicket.borrowDate || "-" }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("hr.debit_tickets.due_date") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ debitTicket.dueDate || "-" }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("hr.debit_tickets.give_back_date") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ debitTicket.giveBackDate || "-" }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-flex>
          <v-flex xs12>
            <validation-provider :name="$t('hr.debit_tickets.approver')" rules="required" v-slot="{ errors }">
              <employee-picker
                v-model="approver"
                :label="$t('hr.debit_tickets.approver')"
                :error-messages="errors"
                :item-disabled="itemDisabled"
              />
            </validation-provider>
          </v-flex>
        </v-card-text>
        <v-card-actions class="dialog-footer">
          <v-spacer></v-spacer>
          <v-btn depressed :loading="loading" @click.stop="handleSubmit(onClickSave)">{{ $t("buttons.save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
  export default {
    name: "TransferDialog",
    components: {
      EmployeePicker: () => import("@/components/selectpickers/EmployeePicker")
    },
    props: {
      value: Boolean,
      debitTicket: {
        type: Object,
        required: false
      }
    },
    data: () => ({
      loading: false,
      approver: null
    }),
    methods: {
      itemDisabled(item) {
        return item.id === this.$store.state.auth.user.id;
      },
      onClickCancel() {
        this.approver = null;
        this.$refs.observer.reset();
        this.$emit("input", false);
      },
      onClickSave() {
        this.loading = true;
        this.$api.debitTicketService
          .transferRequest(this.debitTicket.id, this.approver)
          .then(response => {
            if (!response.data.error) {
              this.onClickCancel();
            } else if (response.data.error.code === 761) {
              this.$helpers.showNotification(
                this.$t("hr.debit_tickets.DEBIT_TRANSFER_DUPLICATE_EXCEPTION"),
                "error",
                true
              );
            }
          })
          .catch(console.error)
          .finally(() => (this.loading = false));
      }
    }
  };
</script>

<style scoped></style>
